body {
  margin: 0;
  user-select: none;
  overflow-x: hidden;
  background-image: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Monsieur+La+Doulaise&display=swap');

:root{
  font-family: Montserrat;
  font-size: var(--fontsize-medium);
  font-weight: 400;
}

a{
  text-decoration: none;
  color: unset;
}

:root {
  --black: #000000;
  --lightgrey: #8c8c8c;
  --grey: #565656;
  --white: #ffffff;
  --light: rgb(245, 245, 245);
  --blue: #0d6efd;
  --red: #f20000;
  --green: #389513;
  --yellow: #dede22;
  --orange: #e8a21f;

  --fontsize-small: 12px; /* 12px */
  --fontsize-regular: 14px; /* 14px */
  --fontsize-medium: 16px; /* 16px */
  --fontsize-large: 18px; /* 18px */
  --fontsize-big: 20px; /* 20px */
  --fontsize-huge: 22px; /* 22px */
  --fontsize-massive: 24px; /* 24px */
  --fontsize-gigantic: 26px; /* 26px */
  --fontsize-enormous: 30px; /* 30px */
  --fontsize-humongous: 36px; /* 36px */
  --fontsize-outrageous: 40px; /* 40px */
  --fontsize-biggest: 50px;
  --fontsize-tyrann: 75px;
}

@media (max-width: 768px) {
  :root {
    --fontsize-small: 10px; /* 12px */
    --fontsize-regular: 12px; /* 14px */
    --fontsize-medium: 14px; /* 16px */
    --fontsize-large: 16px; /* 18px */
    --fontsize-big: 18px; /* 20px */
    --fontsize-huge: 20px; /* 22px */
    --fontsize-massive: 22px; /* 24px */
    --fontsize-gigantic: 24px; /* 26px */
    --fontsize-enormous: 28px; /* 30px */
    --fontsize-humongous: 34px; /* 36px */
    --fontsize-outrageous: 38px; /* 40px */
    --fontsize-biggest: 48px;
    --fontsize-tyrann: 70px;
  }
}

.small{font-size: var(--fontsize-small)}
.regular{font-size: var(--fontsize-regular)}
.medium{font-size: var(--fontsize-medium)}
.large{font-size: var(--fontsize-large)}
.big{font-size: var(--fontsize-big)}
.huge{font-size: var(--fontsize-huge)}
.massive{font-size: var(--fontsize-massive)}
.gigantic{font-size: var(--fontsize-gigantic)}
.enormous{font-size: var(--fontsize-enormous)}
.humongous{font-size: var(--fontsize-humongous)}
.outrageous{font-size: var(--fontsize-outrageous)}
.biggest{font-size: var(--fontsize-biggest)}
.tyrann{font-size: var(--fontsize-tyrann)}

.font-semibold{
  font-weight: 600;
}

.lightgrey{
  color: var(--lightgrey);
}

.blue{
  color: var(--blue);
}

.green{
  color: var(--green);
}

.flex-row{
  display: flex;
  flex-direction: row;
}

.flex-col{
  display: flex;
  flex-direction: column;
}

.justify-between{
  justify-content: space-between;
}

.justify-center{
  justify-content: center;
}

.gap-3{
  gap: 15px;
}

.gap-1{
  gap: 5px;
}

.hidden{
  display: none;
}

.items-center{
  align-items: center;
}

.contact-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 125.1px);
  font-size: var(--fontsize-huge);
  gap: 5px;
}

.t-black{
  color: var(--black);
}

.t-white{
  color: var(--white);
}