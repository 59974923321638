.quick-menu{
    background-color: var(--light);
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 20%;
    height: fit-content;
    position: sticky;
    top: 10px;
}

.projects-main{
    padding: 10vh 20%;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 50px;
    min-height: calc(80vh - 125px);
}

.project-section-content{
    background-color: blue;
    border-radius: 5px;
    padding: 10px;
    height: 200px;
}

.project-section{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.project-content{
    width: 80%;
}

.project-info-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.bg-project{
    position: relative;
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.text-project-title {
    position: absolute;
    z-index: 2;
    color: black;
    text-align: center;
    bottom: 40px;
}

.pr-title{
    font-size: var(--fontsize-biggest);
    font-weight: 800;
}

.pr-subtitle{
    font-size: var(--fontsize-massive);
}

.project-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-overlay {
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);    
}

.pr-section-title{
    font-weight: 600;
    font-size: var(--fontsize-huge);
}

.image-wrapper {
    position: relative;
    width: 100%;
    max-width: 450px;
    height: 300px;
    overflow: hidden;
    border-radius: 5px;
}

.image-wrapper:hover .image-container {
    transform: scale(1.05); /* Scale up the image slightly */
  }
  
  .image-container {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 35%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)); /* Black gradient fading to transparent */
    display: flex;
    align-items: flex-end;
    justify-content: left;
  }

.dot {
    height: 6px;
    width: 6px;
    margin: 0 5px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.dot.active {
    background-color: #000;
}

.dots-container {
    display: flex;
    justify-content: center;
    background-color: var(--light);
    padding: 5px;
    border-radius: 50px;
    position: absolute;
    bottom: 15px;
}

.main-box-image-slider{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 5px;
}

.image-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 5px var(--light);
}

.slider-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
}

.slider-wrapper {
    display: flex;
    transition: transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
}

.bigImageProject {
    width: 100%;
    object-fit: cover;
    height: 55vh;
    border-radius: 5px;
    transition: opacity 0.4s ease-in-out;
}

.slider-button {
    background-color: var(--white);
    opacity: 75%;
    color: var(--black);
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 500px;
    position: absolute;
    box-shadow: 0px 0px 5px var(--light);
}

#right-button{
    right: 20px;
    top: 50%;
}

#left-button{
    left: 20px;
    top: 50%;
}

.download-item{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.content-project{
    display: flex;
    flex-direction: column;
    width: 55vw;
    gap: 50px;
}

@media (max-width: 768px) {
    .content-project{
        width: 100%;
    }

    .bigImageProject {
        height: 25vh;
    }

    .slider-button {
        display: none;
    }

    .projects-main{
        padding: 20px;
        min-height: calc(100vh - 158px);
        justify-content: center;
    }

    .pr-title{
        font-size: var(--fontsize-humongous);
        font-weight: 800;
    }

    .bg-project{
        height: 350px;
    }

    .text-project-title {
        bottom: 20px;
    }
}