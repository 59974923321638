
.mogul-picture{
    width: 400px;
    height: 400px;
    object-fit: cover;
}

.mogul{
    width: 400px;
}

.mogul-name{
    font-size: var(--fontsize-big);
    font-weight: 800;
    text-transform: uppercase;
}

.mogul-occupation{
    font-size: var(--fontsize-medium);
    margin-bottom: 10px;
}

.mogul-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 50px;
    align-items: center;
    justify-content: center;
}

.otherInspo{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 60%;
    margin-top: 50px;
    font-style: italic;
}

@media (max-width: 768px) {
    .mogul-picture{
        width: 100%;
        height: 400px;
        object-fit: cover;
    }
    
    .mogul{
        width: 100%;
    }

    .otherInspo{
        width: 80%;
        margin-top: 10px;
        font-size: var(--fontsize-small);
    }
}
