/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    border-radius: 5px;
    gap: 15px;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    align-items: center;
  }

  .thumbnail-preview {
    display: flex;
    justify-content: flex-start; /* Align thumbnails to the left */
    gap: 10px;
    align-items: center;
    overflow-x: auto; /* Enable horizontal scrolling */
    padding: 10px; /* Add some padding for spacing */
    max-width: 90vw; /* Make it responsive */
    max-height: 100px; /* Limit the height of the thumbnail preview */
    scroll-snap-type: x mandatory; /* Ensure smooth snapping when scrolling */
  }

  .thumbnail-preview::-webkit-scrollbar {
    display: none;
  }
  
  .thumbnail {
    width: 60px;
    height: 40px;
    object-fit: cover;
    border-radius: 2px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s ease;
    scroll-snap-align: start;
  }

  .thumbnail.selected {
    opacity: 1;
    border: 1px solid var(--white);
    height: 45px;
    width: 75px;
  }
  
  .modal-image {
    max-width: 90vw;
    height: 80vh;
    border-radius: 5px;
    object-fit: cover;
  }
  
.close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: #fff;
    color: black;
    border: none;
    padding: 5px 10px;
    width: 35px;
    box-shadow: 0px 0px 5px var(--lightgrey);
    height: 35px;
    cursor: pointer;
    border-radius: 500px;
    align-items: center;
    justify-content: center;
    display: flex;
}
  
@media (max-width: 768px) {
    .close-button {
        width: 25px;
        height: 25px;
    }

    .thumbnail {
      width: 50px;
      height: 30px;
    }
  
    .thumbnail.selected {
      height: 35px;
      width: 60px;
    }

    .modal-image {
      height: 24vh;
    }
}