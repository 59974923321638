.quote{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: row;
    font-size: var(--fontsize-big);
}

.fancy-word{
    font-family: Sonder;
    font-size: var(--fontsize-enormous);
    font-style: italic;
}

.quote-row{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.projects{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    row-gap: 50px;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}

.project{
    display: flex;
    flex-direction: column;
    width: calc(33% - 10px);
}

.project-image{
    object-fit: cover;
    width: 100%;
    height: 300px;
    border-radius: 2px;
    border: 1px solid var(--black);
    border-radius: 3px;
}

.project-image:hover{
    filter: invert(1);
}

.main{
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 10vh 30px;
    min-height: calc(80vh - 123px);
}

.projects-title{
    margin-bottom: 15px;
    font-size: var(--fontsize-large);
    font-weight: 600;
}

.about-main{
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 60%;
    padding: 5% 20%;
}

.paragraph{
    font-size: var(--fontsize-big);
    text-align: justify;
}

.project-title{
    font-size: var(--fontsize-outrageous);
    font-weight: 800;
    text-transform: uppercase;
}

.project-subtitle{
    color: var(--lightgrey);
    font-size: var(--fontsize-regular);
}

.project-content{
    padding: 10px 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.video{
    width: 100%;
    height: 400px;
    background-color: aqua;
    border-radius: 5px;
}

.intro-text{
    font-size: var(--fontsize-gigantic);
    text-transform: uppercase;
    text-align: center;
    font-family: Vensfolk;
}

.intro-swirl{
    font-size: 150px;
    font-family: "Monsieur La Doulaise", cursive;
    position: absolute;
    text-align: center;
}

.tilt-container {
    width: 100%;
    height: 200px;
    perspective: 1000px;
}
  
.tilt-logo {
    width: 100%;
    transition: transform 0.1s ease-out;
}

.alternative{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
    align-items: center;
    padding: 20px;
    width: calc(100% - 40px);
    border-radius: 2px;
    border: 1px solid var(--lightgrey);
    transition: background 0.3s ease;
    min-height: 70px;
}

.alternative:hover {
    background-size: cover;
    background-position: center;
    color: #fff;
    background-image: var(--background-img);
    text-shadow: 4px 4px 10px rgb(0, 0, 0);
}

.alternative:hover .small {
    display: none;
}

@media (max-width: 768px) {
    .alternative {
        background-image: var(--background-img);
        background-size: cover;
        background-position: center;
        color: #fff;
        text-shadow: 4px 4px 10px rgb(0, 0, 0);
    }

    .alternative .small {
        display: none;
    }
}

.project-main{
    padding: 15px;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 50px;
    min-height: calc(80vh - 125px);
}

.linkRow{
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    padding: 5px;
    justify-content: space-between;
    align-items: center;
    font-size: var(--fontsize-regular);
    cursor: pointer;
    background-color: var(--light);
}

.linkText{
    color: var(--green);
    display: flex;
    align-items: center;
    gap: 5px;
}


@media (max-width: 768px) {
    .projects{
        flex-direction: column;
    }

    .project{
        width: 100%;
    }

    .about-main{
        padding: 5%;
        width: 90%;
    }

    .project-image{
        height: 225px;
    }

    .project-title{
        font-size: var(--fontsize-enormous);
    }

    .alternative{
        padding: 15px;
        width: calc(100% - 30px);
    }

    .project-main{
        padding: 5%;
    }

    .intro-swirl{
        font-size: 60px;
    }

    .intro-text{
        font-size: var(--fontsize-huge);
    }

    .main{
        padding: 2vh;
        min-height: calc(96vh - 118px);
    }

    .paragraph{
        font-size: var(--fontsize-large);
        text-align: justify;
    }
}