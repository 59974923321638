.nav-bar {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
    z-index: 200;
    position: relative;
    box-shadow: 0px 2px 5px var(--light);
  }
  
  .name {
    font-size: var(--fontsize-humongous);
    font-weight: 800;
  }
  
  .icon-box {
    background-color: var(--light);
    width: 35px;
    height: 35px;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
  }
  
  .nav-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    align-items: center;
    transition: transform 0.5s ease;
  }
  
  .nav-content.open { 
    transform: translateX(0);
    width: 100%;
    background-color: var(--black);
    color: var(--white);
  }
  
  @media (max-width: 768px) {
    .icon-box {
      display: flex;
    }
  
    .nav-content {
      display: none;
      flex-direction: column;
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: var(--black);
      color: var(--white);
      justify-content: center;
      align-items: center;
      transform: translateX(100%);
      transition: transform 0.5s ease;
    }
  
    /* Show nav content when open */
    .nav-content.open {
      display: flex;
      transform: translateX(0);
      z-index: -1;
    }
  
    .nav-content a {
      color: var(--white);
      font-size: var(--fontsize-large);
      text-decoration: none;
      padding: 15px;
    }
  }
  