.timeline-switcher {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    row-gap: 10px;
    flex-wrap: wrap;
}

.cv-section {
    display: flex;
    flex-direction: column;
    gap: 100px;
    align-items: center;
}

.cv-subsection {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    background-color: var(--light);
    padding: 20px;
    border-radius: 5px;
}

.subsection-header {
    font-size: var(--fontsize-massive);
    font-weight: 600;
}

.timeline-switcher-element {
    font-size: var(--fontsize-large);
    cursor: pointer;
}

.timeline-switcher-element.active {
    text-decoration: underline;
    color: var(--green);
    font-weight: 600;
}

.timeline-content-date {
    margin-top: 15px;
    position: relative;
    width: 20%;
}

.timeline-dot {
    width: 10px;
    height: 10px;
    background-color: green; /* Adjust the color */
    border-radius: 50%;
    position: relative;
    z-index: 1;
}

.timeline-content-element:not(:last-child) .timeline-content-date::after{
    content: '';
    position: absolute;
    top: 18px;
    left: 4px;
    width: 1px;
    height: calc(100% + 20px); /* Adjust height to fit between dots */
    z-index: 0;
    border-left: 2px dotted var(--lightgrey); /* Dotted vertical line */
}

.timeline-date {
    margin-top: 10px;
    font-size: var(--fontsize-small); /* Adjust font size for date */
}

.timeline-content-element {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: stretch;
    gap: 10px;
}

.cv-section-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cv-main {
    min-height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding: 5% 15%;
}

.cv-info-row{
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.timeline-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
}

.cv-image{
    height: 400px;
    width: 500px;
    object-fit: cover;
}

.personal-col{
    display: flex;
    flex-direction: column;
}

.personal-title{
    color: var(--lightgrey);
    font-size: var(--fontsize-small);
    font-weight: 600;
}

.personal-info{
    font-size: var(--fontsize-medium);
}

.personal-infos{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.timeline-content-info{
    display: flex;
    flex-direction: column;
    width: calc(80% - 5px);
}

.box{
    max-height: 35px;
    width: 35px;
    object-fit: contain;
    border-radius: 5px;
}

.flags{
    height: 25px;
    object-fit: cover;
}

.timeline-content-skill {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    align-items: flex-start;
    gap: 10px;
    position: relative;
    width: calc(20% - 5px);
}

.timeline-skill-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    position: relative;
    width: 100%;
}

@media (max-width: 768px) {
    .cv-info-row{
        flex-direction: column;
    }

    .cv-main {
        min-height: calc(100vh - 50px);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
        padding: 5%;
    }

    .cv-image{
        width: 100%;
    }

    .personal-infos{
        width: 100%;
    }

    .timeline-content-info{
        width: calc(70% - 5px);
    }

    .timeline-content-date {
        width: calc(30% - 5px);
    }

    .timeline-content-skill {
        width: calc(45% - 5px);
    }

    .box{
        max-height: 25px;
        width: 25px;
        object-fit: contain;
        border-radius: 5px;
    }
    
    .flags{
        height: 20px;
        object-fit: cover;
    }

    .timeline-switcher {
        column-gap: 15px;
    }

    .cv-subsection {
        width: unset;
    }
}