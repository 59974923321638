.social-row{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.legal-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-social{
    font-size: var(--fontsize-big);
    cursor: pointer;
}

.footer{
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.staple{
    font-size: var(--fontsize-small);
}